/* Card styling */
/* body { */
/* background: linear-gradient(135deg, #333, #990a00) !important; */
/* color: #f5f5f5 !important;
    font-family: Arial, sans-serif !important;
    margin: 0;
    padding: 0; */
/* } */

.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 20px 0;
    padding: 20px;
    background: rgba(255, 255, 255, 0.3) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(10px) !important;    ;
}

.card-image {
    width: 40%;
    height: auto;
    border-radius: 8px;
    margin-right: 20px;
}

.card-content {
    width: 60%;
}

.intro-section img,
.festival-section img {
    width: 70%;
    height: auto;
    object-fit: cover;
    padding: 20px;
}


h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
    text-align: left !important;
}

p {
    font-size: 1.1em;
    color: black;
    line-height: 1.6;
}

.breadcrumbs {
    font-size: 0.9em;
    color: #999;
}

.page-title {
    color: #fff;
    padding: 60px 0;
    text-align: left;
}